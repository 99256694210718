//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base/layout';
@import 'layout/layout';

/* TODO: Remover estilização abaixo quando o recaptcha for totalmnte implmentado */
.grecaptcha-badge {
  display: none !important;
}

/* CUSTOM STYLES */
.button-style-reseted {
  background: none;
  border: none;
  outline: none;
}

.event-image {
  img {
    width: 100%;
  }
}

.group-aniversario {
  opacity: 0.5;
  transition: all 0.2s linear;
  pointer-events: none;
  background-color: $primary-light;
  padding: 20px;
  border-radius: 5px;

  * {
    pointer-events: none;
  }

  &.show {
    opacity: 1;
    pointer-events: all;

    * {
      pointer-events: all;
    }
  }
}

.quota-container {
  opacity: 0.5;
  transition: all 0.2s linear;
  pointer-events: none;

  * {
    pointer-events: none;
  }

  &.show {
    opacity: 1;
    pointer-events: all;

    * {
      pointer-events: all;
    }
  }
}

.list-change-container {
  opacity: 0.5;
  transition: all 0.2s linear;
  pointer-events: none;

  * {
    pointer-events: none;
  }

  &.show {
    opacity: 1;
    pointer-events: all;

    * {
      pointer-events: all;
    }
  }
}

.container-options {
  opacity: 0.5;
  transition: all 0.2s linear;
  pointer-events: none;

  * {
    pointer-events: none;
  }

  &.show {
    opacity: 1;
    pointer-events: all;

    * {
      pointer-events: all;
    }
  }
}

.main-menu,
.single-menu {
  display: block;

  &.hide {
    display: none;
  }
}

.page-title {
  min-width: 50%;
}

.w-5 {
  width: 5% !important;
}
.w-10 {
  width: 10% !important;
}
.w-15 {
  width: 15% !important;
}
.w-20 {
  width: 20% !important;
}
.w-30 {
  width: 30% !important;
}
.w-40 {
  width: 40% !important;
}
.w-60 {
  width: 60% !important;
}

.bs-fs-1 {
  font-size: 35px;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  position: relative;
  float: left;
}

.text-oh {
  position: relative;
  float: left;
  overflow: hidden;
}

.bg-purple {
  background-color: #6609bc;
}

.bg-pink {
  background-color: #f1416c;
}

.bg-red {
  background-color: #f14141;
}

.bg-blue {
  background-color: #0996bc;
}

.bg-orange {
  background-color: #bc6f09;
}

.bg-yellow {
  background-color: #bcb309;
}

.bg-darkblue {
  background-color: #095abc;
}

.bg-gray {
  background-color: $gray-500;
}

.bg-green {
  background-color: #98c400 !important;
  color: #fff !important;

  .ki-duotone {
    color: #fff !important;
  }

  &:hover {
    background-color: #89b000 !important;
    color: #fff !important;
  }
}

.form-switch {
  cursor: pointer;
}

.btn {
  &.btn-bg-danger {
    &:hover {
      background-color: $danger-active !important;

      &:not(.btn-active) {
        background-color: $danger-active !important;
      }
    }
  }
}

.bullet-vertical {
  width: 8px !important;
}

.event-desc {
  a {
    color: #fff;
    text-decoration: underline;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive::before {
  display: block;
  content: '';
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

// FIX MODAL
body {
  .modal {
    .modal-body {
      max-height: 50vh;
    }
  }

  .modal_container {
    display: none !important;
  }

  &.modal-promoter-add {
    .modal_container.modal-promoter-add {
      display: block !important;
    }
  }

  &.modal-add {
    .modal_container.modal-add {
      display: block !important;
    }
  }

  &.modal-add-guest {
    .modal_container.modal-add-guest {
      display: block !important;
    }
  }

  &.modal-delete-guest {
    .modal_container.modal-delete-guest {
      display: block !important;
    }
  }

  &.modal-promoter-edit {
    .modal_container.modal-promoter-edit {
      display: block !important;
    }
  }

  &.modal-promoter-delete {
    .modal_container.modal-promoter-delete {
      display: block !important;
    }
  }

  &.modal-add-guests {
    .modal_container.modal-add-guests {
      display: block !important;
    }
  }

  &.modal-checkin-show {
    .modal_container.modal-checkin-show {
      display: block !important;
    }
  }

  &.modal-cancel-checkin-show {
    .modal_container.modal-cancel-checkin-show {
      display: block !important;
    }
  }

  &.modal-cancel-delete-account-show {
    .modal_container.modal-cancel-delete-account-show {
      display: block !important;
    }
  }

  &.modal-success-show {
    .modal_container.modal-success-show {
      display: block !important;
    }
  }
}

.filter_item {
  background: #f8f8f8;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  transition: all 0.2s linear;
  opacity: 0.5;
  cursor: pointer;

  &.active {
    opacity: 1;
  }
}

.amigos-container {
  background-color: #f9f9f9 !important;

  input {
    background-color: #eaeaea !important;
  }
}

.container_checkin {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__header,
  &__footer {
    width: 100%;
    height: 50px;
    min-height: 50px;
    display: flex;
    flex: 0 1 50px;
    background-color: rgb(0 0 0 / 50%);
  }

  &__middle {
    width: 100%;
    flex: 1 1 auto;
    overflow: auto;
  }
}

.ttu {
  text-transform: uppercase;
}

.tdlt {
  text-decoration: line-through;
}

// Menu do usuário
.menu-column {
  width: auto !important;
  max-width: 100vw;
}

//Tablets
@include media-breakpoint-down(xl) {
  // New Event
  .stepper-nav {
    flex-direction: row !important;
    gap: 2.5rem;

    padding: 2.5rem 0 !important;

    .stepper-line {
      display: none !important;
    }
  }

  // Event Dashboard
  #page-event-dashboard {
    .card {
      margin-bottom: 2rem;
    }
  }
}

// Tablet mode
@include media-breakpoint-down(lg) {
  // Event Guests
  #guests-main-content {
    flex-direction: column-reverse !important;
    gap: 16px;
  }
}

// Bigger then Mobile mode
@include media-breakpoint-up(md) {
  .th-sm-devices,
  .td-sm-devices {
    display: none;
  }

  .th-default-devices,
  .td-default-devices {
    display: table-cell;
  }
}

// Mobile mode
@include media-breakpoint-down(md) {
  // General
  .page-title {
    gap: 8px;
  }

  .th-sm-devices,
  .td-sm-devices {
    display: table-cell;
  }

  .td-sm-devices div {
    justify-content: flex-start !important;
    flex-direction: column;
    gap: 5px;
  }

  .th-default-devices,
  .td-default-devices {
    display: none;
  }

  // Event Edit
  .date-and-hour-fields {
    width: 100% !important;

    &:first-child {
      margin-bottom: 2.5rem;
    }
  }

  // Event Checkin Screen
  .container_checkin__header {
    flex: none;
    height: auto;

    .container_checkin__title {
      flex-direction: column;
      align-items: flex-start !important;
      gap: 8px;

      width: 100%;
      margin: 8px 16px;

      .menu-link {
        margin: 0 !important;
      }
    }

    .container_checkin__guests {
      text-align: center;
    }
  }
}

// Small Mobile mode
@include media-breakpoint-down(sm) {
  // General
  .full-w-fields {
    flex-direction: column;
    justify-content: flex-start !important;
    gap: 8px;

    width: 100%;

    .row,
    .fv-row,
    .form-check {
      width: 100% !important;

      div {
        width: 100% !important;
      }
    }
  }

  .full-w-sm-fields {
    gap: 2.5rem;

    .full-w-sm-field {
      width: 100% !important;
    }
  }

  // New Event
  .stepper-nav {
    flex-direction: column !important;
    gap: 0;

    padding: 2.5rem 0 !important;

    .stepper-line {
      display: block !important;
    }
  }

  #public-list-configs-table {
    table-layout: fixed;

    td {
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }
  }

  // Event Dashboard
  #page-event-dashboard {
    .table-line-with-progress-chart {
      flex-direction: column;
      gap: 8px;

      div {
        width: 100% !important;
        max-width: 100% !important;
        flex-grow: unset !important;
      }
    }
  }

  // Event Lists
  .birthday-list-icon {
    height: 24px;
    min-height: 24px;
    max-height: 24px;

    width: 24px;
    min-width: 24px;
    max-width: 24px;
  }
}
